





































import { kebabCase } from 'library/helpers/formatting'

export default
	props: block: Object

	computed:
		classes: -> [
			kebabCase @block.layout || 'image-right', 'layout'
			kebabCase @block.imageWidth || 'wide', 'image-size'
			kebabCase @block.textAlignment || 'left', 'align-text'
			kebabCase @block.copyWidth || 'full', 'copy-width'
		]

	 	# Is the asset a GIF?
		isGIF: -> @asset?.mimeType == 'image/gif'

		# Ease of access
		asset: -> @block.asset?[0]

		# Make gif url
		GIFSrc: ->
			imgixUrl = process.env.IMGIX_URL
			if imgixUrl then "#{imgixUrl}/#{@asset.path}"
			else @asset.url

